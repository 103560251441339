import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './References.css';
import { useTranslation } from 'react-i18next';

const References = () => {
  const { t, i18n } = useTranslation(); // i18n konfigürasyon dosyanızın yolu
  const [references, setReferences] = useState([]);

  useEffect(() => {
    const fetchReferences = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/references/list`);

        setReferences(response.data);
      } catch (error) {
        console.error('Error fetching references:', error);
      }
    };

    fetchReferences();
  }, []);

  // Her iki referansı bir grup olarak gruplandır
  const groupedReferences = references.reduce((acc, ref, index) => {
    if (index % 2 === 0) {
      acc.push([ref]);
    } else {
      acc[acc.length - 1].push(ref);
    }
    return acc;
  }, []);

  return (
    <div id="references">
      <div className="ref-container">
        <div className="ref-left">
          <div className="ref-say">
            <span>12</span>
          </div>
          <div className="ref-text">
            {i18n.t('general:tecrube_1')} <br />  {i18n.t('general:tecrube_2')}
          </div>
        </div>
        <div className="ref-right">
          <div className="contain">
            {/* İkişerli gruplar üzerinde map yap ve her bir çift için bir div oluştur */}
            {groupedReferences.map((group, index) => (
              <div className="mobile-ref-group" key={index}>
                {group.map((ref) => (
                  <div className="mobile-ref" key={ref.reference_id}>
                    <a href={ref.button_link} target="_blank" rel="noopener noreferrer">
                      <div className="references">
                        <img src={`${process.env.REACT_APP_API_URL}/${ref.images[0]?.image_url}`} alt={ref.title} />
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default References;
