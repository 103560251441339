import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import i18n from 'i18next'
import './Contact.css';
import ProfilCard from '../../components/profilcard/ProfilCard';



const validate = (values) => {
  const errors = {};

  if (!values.category) {
    errors.category = i18n.t('contact:categoryRequired');
  }
  if (!values.fullname) {
    errors.fullname = i18n.t('contact:fullnameRequired');
  }
  if (!values.tel) {
    errors.tel = i18n.t('contact:phoneRequired');
  } else if (!/^\+?\d{10,15}$/.test(values.tel)) {
    errors.tel = i18n.t('contact:phoneNot');
  }

  if (!values.email) {
    errors.email = i18n.t('contact:emailRequired');
  } else if (
    !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(values.email)
  ) {
    errors.email = i18n.t('contact:emailNot');
  }

  if (!values.message) {
    errors.message = i18n.t('contact:messageRequired');
  } else if (values.message.length < 10) {
    errors.message = i18n.t('contact:messageNot');
  }

  return errors;
};

const Contact = () => {
  const { i18n } = useTranslation();
  const [metaData, setMetaData] = useState({ title: '', description: '' });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/pages/list/${i18n.language}`)
      .then(response => {
        if (response.data && response.data.length) {
          // Dil koduna ve sayfa ismine göre doğru meta verileri bul
          const pageMeta = response.data.find(page => page.page_name === (i18n.language === 'en' ? 'Contact' : 'İletişim'));
          if (pageMeta) {
            setMetaData({
              title: pageMeta.meta_title,
              description: pageMeta.meta_description
            });
          }
        }
      })
      .catch(error => console.error('Meta veri çekilirken hata oluştu:', error));
  }, [i18n.language]);
  
  const [successMessage, setSuccessMessage] = useState(null);
  const [formValues, setFormValues] = useState({
    fullname: '',
    category: '',
    message: '',
    tel: '',
    email: '',
  });

  const [formErrors, setFormErrors] = useState({});

  // İşlem doğrulama için durum değişkenleri
  const [num1, setNum1] = useState(Math.floor(Math.random() * 10));
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10));
  const [userAnswer, setUserAnswer] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
  
    // İşlem doğrulamasını kontrol et
    if (parseInt(userAnswer, 10) !== num1 + num2) {
      errors.calculation = i18n.t('contact:totalFalse');
    } else {
      delete errors.calculation;
    }
  
    if (Object.keys(errors).length === 0) {
      try {
        // Axios ile API'ye POST isteği gönder
        
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/forms/add`, formValues);
  
        // Başarı mesajını ayarla ve formu sıfırla
        setSuccessMessage(i18n.t('contact:formSubmit'));
        setFormValues({
          fullname: '',
          category: '',
          message: '',
          tel: '',
          email: '',
        });
  
     // Yeni rastgele sayılar ayarla
     setNum1(Math.floor(Math.random() * 10));
     setNum2(Math.floor(Math.random() * 10));
     setUserAnswer('');
   } catch (error) {
     // Hata durumunda kullanıcıya bilgi ver
     console.error('Form gönderme hatası:', error);
     setSuccessMessage(i18n.t('contact:formNotSubmit'));
   }
 } else {
   setFormErrors(errors);
   setSuccessMessage(null);
 }
};

  return (
    
    <div id="contactForm">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>

      <div className='pages-title'>
       <h1>{i18n.t('contact:title')}</h1>
       </div>
      <div className="containerForm">

        <form onSubmit={handleSubmit}>
        <div className="col-6">
            <h5>{i18n.t('contact:titleDesc')}</h5>

            <div className="form-group">
              <label htmlFor="fullname">{i18n.t('contact:fullname')}</label>
              <input
                id="fullname"
                name="fullname"
                value={formValues.fullname}
                onChange={handleChange}
                placeholder={i18n.t('contact:fullnamePlaceHolder')}
              />
              {formErrors.fullname && (
                <div className="error">{formErrors.fullname}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="email">{i18n.t('contact:email')}</label>
              <input
                id="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                placeholder="test@gmail.com"
              />
              {formErrors.email && (
                <div className="error">{formErrors.email}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="tel">{i18n.t('contact:phone')}</label>
              <input
                id="tel"
                name="tel"
                value={formValues.tel}
                onChange={handleChange}
                placeholder="+0(5XX) XXX XX XX"
              />
              {formErrors.tel && <div className="error">{formErrors.tel}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="category">{i18n.t('contact:category')}</label>
              <select
                id="category"
                name="category"
                value={formValues.category}
                onChange={handleChange}
              >
                <option value="">{i18n.t('contact:categoryPlaceHolder')}</option>
                <option value="Web Tasarım">{i18n.t('contact:webdesign')}</option>
                <option value="E-Ticaret">{i18n.t('contact:ecommerce')}</option>
                <option value="Mobil Uygulama">{i18n.t('contact:mobilapp')}</option>
                <option value="Özel Yazılım">{i18n.t('contact:specialsoftware')}</option>
                <option value="Startup Proje">{i18n.t('contact:startapp')}</option>
                <option value="SEO">{i18n.t('contact:seo')}</option>
              </select>
              {formErrors.category && (
                <div className="error">{formErrors.category}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="message">{i18n.t('contact:message')}</label>
              <textarea
                placeholder={i18n.t('contact:messagePlaceHolder')}
                id="message"
                name="message"
                value={formValues.message}
                onChange={handleChange}
              />
              {formErrors.message && (
                <div className="error">{formErrors.message}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="calculation">
              {i18n.t('contact:security')} {num1} + {num2} = ?
              </label>
              <input
                placeholder={i18n.t('contact:total')}
                type="number"
                id="calculation"
                name="calculation"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
              />
              {formErrors.calculation && (
                <div className="error">{formErrors.calculation}</div>
              )}
            </div>
            <button type="submit">{i18n.t('contact:submit')}</button>
            {successMessage && <div className="success">{successMessage}</div>}
          </div>
        <div className="col-6 formImg notPictureImg">
          <ProfilCard/>
          </div>

         
        </form>
      
      </div>
      <div className="container"></div>
    </div>
  );
};

export default Contact;
