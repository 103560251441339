import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Hizmetler.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { BsCodeSlash } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { TfiApple } from 'react-icons/tfi';
import { IoLaptopOutline } from 'react-icons/io5';
import { CiMobile3 } from 'react-icons/ci';
import { IoPulseOutline } from 'react-icons/io5';
import i18n from 'i18next'; // Ya da dil bilgisini aldığınız yerden import edin.

const isMobile = window.innerWidth <= 768;

function truncate(str, length = 70) {
  if (str.length <= length) return str;
  return `${str.substring(0, length)}...`;
}

const Hizmetler = () => {
  const [services, setServices] = useState([]);
  const { i18n } = useTranslation();
  const [metaData, setMetaData] = useState({ title: '', description: '' });


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/pages/list/${i18n.language}`)
      .then(response => {
        if (response.data && response.data.length) {
          // Dil koduna ve sayfa ismine göre doğru meta verileri bul
          const pageMeta = response.data.find(page => page.page_name === (i18n.language === 'en' ? 'Services' : 'Hizmetler'));
          if (pageMeta) {
            setMetaData({
              title: pageMeta.meta_title,
              description: pageMeta.meta_description
            });
          }
        }
      })
      .catch(error => console.error('Meta veri çekilirken hata oluştu:', error));
  }, [i18n.language]);


  useEffect(() => {
    const fetchServices = async () => {
      try {
        const language = i18n.language; // Ya da dil bilgisini aldığınız yerden alın.
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/list/${language}`);
        setServices(response.data);
      } catch (error) {
        console.error('Hizmetler yüklenirken hata oluştu:', error);
      }
    };

    // Dil değiştiğinde servis listesini yeniden yükle
    fetchServices();
  }, [i18n.language]); // Ya da dil bilgisini tuttuğunuz değişkeni buraya ekleyin.

  return (
    <div>
      
      <div id="services">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
        <div className='pages-title'>
          <h1> {i18n.t('general:hizmetTitle')}</h1>
          <p className='services-title'> {i18n.t('general:hizmetDesc')}</p>
        </div>
        <div className='container'>
          <div className='services'>
            {services.map((service) => (
              <div className="services-card" key={service.id}>
                <div className='services-card-body'>
                  <div className='services-card-title'>
                    <BsCodeSlash/>
                    <h3>{service.title}</h3>
                  </div>
                  <div className='services-card-description'>
                    {isMobile ? truncate(service.meta_description) : service.meta_description}
                  </div>
                  <Link to={`/hizmet/${service.url}`}><div className='services-card-button'>Detayı Gör</div></Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hizmetler;