import React, { useEffect, useState } from 'react';
import Hero from '../../components/hero/Hero';
import Projects from '../../components/projects/Projects';
import References from '../../components/references/References';
import Services from '../../components/services/Services';
import Orientation from '../../components/orientation/Orientation';
import Card from '../../components/cards/Card';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Home = () => {
  const { i18n } = useTranslation();
  const [metaData, setMetaData] = useState({ title: '', description: '' });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/pages/list/${i18n.language}`)
      .then(response => {
        if (response.data && response.data.length) {
          // Dil koduna ve sayfa ismine göre doğru meta verileri bul
          const pageMeta = response.data.find(page => page.page_name === (i18n.language === 'en' ? 'Home' : 'Anasayfa'));
          if (pageMeta) {
            setMetaData({
              title: pageMeta.meta_title,
              description: pageMeta.meta_description
            });
          }
        }
      })
      .catch(error => console.error('Meta veri çekilirken hata oluştu:', error));
  }, [i18n.language]);
  return (
    <div>
       <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>


      
      <Hero />
      <Projects projectsPerTab={12} />
      <References />
      <Services />
      <Card />
      <Orientation />
    </div>
  );
};

export default Home;
