import React from 'react';
import { TfiApple } from 'react-icons/tfi';
import { HiOutlineRocketLaunch } from 'react-icons/hi2';
import { GiTechnoHeart } from 'react-icons/gi';
import { AiOutlineQuestion } from 'react-icons/ai';
import { AiFillStar } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';



import './Card.css';

const Card = ({ item }) => {
  const { t, i18n } = useTranslation(); 
  return (
    <div className="card-container-fluid">
      <div className="card-container">
        <div className="cards">
          <div className="card-content">
            <span></span>
            <div className="name">
              <h2>
                {' '}
                <span>
                  <AiOutlineQuestion />
                </span>{' '}
                {i18n.t('card:cardTitle1')}
              </h2>
            </div>
            <p className="description">
            {i18n.t('card:cardDesc1')}
            </p>
          </div>
          <div className="card-content">
            <div className="name">
              <h2>
                <span>
                  <AiFillStar />
                </span>{' '}
                {i18n.t('card:cardTitle2')}
              </h2>
            </div>
            <p className="description">
            {i18n.t('card:cardDesc2')}
            </p>
          </div>
          <div className="card-content">
            <div className="name">
              <h2>
                <span>
                  <GiTechnoHeart />
                </span>
                {i18n.t('card:cardTitle3')}
              </h2>
            </div>
            <p className="description">
            {i18n.t('card:cardDesc3')}
            </p>
          </div>

          <div className="card-content">
            <div className="name">
              <h2>
                <span>
                  <HiOutlineRocketLaunch />
                </span>
                {i18n.t('card:cardTitle4')}
              </h2>
            </div>
            <p className="description">
            {i18n.t('card:cardDesc4')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
