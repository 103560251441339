import React, { useEffect, useState } from 'react';
import './ProjectList.css';
import Projects from '../../components/projects/Projects';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';




const ProjectList = () => {
  const {  i18n, t } = useTranslation();
  const [metaData, setMetaData] = useState({ title: '', description: '' });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/pages/list/${i18n.language}`)
      .then(response => {
        if (response.data && response.data.length) {
          // Dil koduna ve sayfa ismine göre doğru meta verileri bul
          const pageMeta = response.data.find(page => page.page_name === (i18n.language === 'en' ? 'Projects' : 'Projeler'));
          if (pageMeta) {
            setMetaData({ 
              title: pageMeta.meta_title,
              description: pageMeta.meta_description,
              page_name: pageMeta.page_name
            });
          }
        }
      })
      .catch(error => console.error('Meta veri çekilirken hata oluştu:', error));
  }, [i18n.language]);

  return (
    <div>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
   
    <div id="ProjectList">
      <div className="container">
        <div className='pages-title'>
        <h1>{metaData.page_name}</h1>
        </div>
        <Projects />
      </div>
    </div>
    </div>
  );
};

export default ProjectList;
