import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './HizmetDetay.css';

const HizmetDetay = () => {
  const { url } = useParams();
  const [hizmetDetay, setHizmetDetay] = useState(null);

  useEffect(() => {
    const fetchHizmetDetay = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/single/${url}`);
        setHizmetDetay(response.data);
      } catch (error) {
        console.error('Hizmet detayı yüklenirken hata oluştu: ', error);
      }
    };

    fetchHizmetDetay();
  }, [url]);

  

  // Kapak resmini bulmak için
  const coverImage = hizmetDetay?.images.find(img => img.image_type === 'banner');

  // Eğer hizmetDetay null ise (yani henüz yüklenmemişse) hiçbir şey render etme
  if (!hizmetDetay) {
    return null;
  }

  const htmlContent = hizmetDetay?.description.replace(/&lt;/g, "<").replace(/&gt;/g, ">");

  return (
    <div id='hizmet-detay'>
      <div className='container'>
        <img src={coverImage ? `${process.env.REACT_APP_API_URL}/${coverImage.image_url}` : 'images/default-image.jpg'} alt={hizmetDetay.title} />
        
        <div className='hizmet-detay-baslik'>
          {hizmetDetay.title}
        </div>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    </div>
  );
};

export default HizmetDetay;
