import ProjectDetail from '../pages/Projectdetails/ProjectDetail';
import About from '../pages/about/About';
import Contact from '../pages/contact/Contact';
import HizmetDetay from '../pages/hizmetDetay/HizmetDetay';
import Hizmetler from '../pages/hizmetler/Hizmetler';
import Home from '../pages/home/Home';
import ProjectList from '../pages/projectlist/ProjectList';

const PageRoutes = [
  {
    name: 'home',
    path: '/',
    component: Home,
    translations: {
      tr: '/',
      en: '/en',
    },
  },
  {
    name: 'iletisim',
    path: '/iletisim',
    component: Contact,
    translations: {
      tr: '/iletisim',
      en: '/en/contact',
    },
  },
  {
    name: 'hakkimizda',
    path: '/hakkimizda',
    component: About,
    translations: {
      tr: '/hakkimizda',
      en: '/en/about-us',
    },
  },
  {
    name: 'projeler',
    path: '/projeler',
    component: ProjectList,
    translations: {
      tr: '/projeler',
      en: '/en/projects',
    },
  },
  {
    name: 'hizmetler',
    path: '/hizmetler',
    component: Hizmetler,
    translations: {
      tr: '/hizmetler',
      en: '/en/services',
    },
  },

  {
    name: 'proje',
    path: '/proje/:url',
    component: ProjectDetail,
    translations: {
      tr: '/proje/:url',
      en: '/en/project/:url',
    },
  },

  {
    name: 'hizmetDetay',
    path: '/hizmet-detay',
    component: HizmetDetay,
    translations: {
      tr: '/hizmet/:url',
      en: '/en/service/:url',
    },
  },
  // Diğer sayfalarınızı buraya ekleyin
];

export default PageRoutes;
