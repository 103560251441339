import React, { useState } from 'react';
import './Faq.css';
import { useTranslation } from 'react-i18next';

const Faq = () => {
  const [expandedItem, setExpandedItem] = useState(null);
  const { t } = useTranslation();

  const faqItems = [
    {
      title: t('faq:faqQuestion1'),
      content:  t('faq:faqAnswer1')
    },
    {
      title: t('faq:faqQuestion2'),
      content:  t('faq:faqAnswer2')
    },
    {
      title: t('faq:faqQuestion3'),
      content:  t('faq:faqAnswer3')
    },
    {
      title: t('faq:faqQuestion4'),
      content:  t('faq:faqAnswer4')
    },
    // Diğer sorularınız burada yer alacak.
  ];

  const toggleAccordion = (index) => {
    if (expandedItem === index) {
      setExpandedItem(null);
    } else {
      setExpandedItem(index);
    }
  };

  return (
    <div className="container-accordion">
      <h2>{t('about:sss')}</h2>
      <div className="accordion">
        {faqItems.map((item, index) => (
          <div className="accordion-item" key={index}>
            <button
              onClick={() => toggleAccordion(index)}
              aria-expanded={expandedItem === index ? true : false}
            >
              <span className="accordion-title">{item.title}</span>
              <span className="icon" aria-hidden="true"></span>
            </button>
            {expandedItem === index && (
              <div className="accordion-content">
                <p>{item.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
