import React from 'react';
import './ProfilCard.css';
import { FaInstagram } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaGithub } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import { BsTelephoneForward } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { Link} from 'react-router-dom';






const ProfilCard = () => {
  const {  i18n, t } = useTranslation();
  return (
    <div id="profilCard">
      <article className="profile">
        <div className="profile-image">
          <img src="../images/logo-sm.png"   alt="Profile" />
        </div>
        <h2 className="profile-username">ORWYS</h2>
        <small className="profile-user-handle">@ORWYS</small>
   
        <div className="profile-actions">
          <Link to="https://github.com/orwysoft"   target="_blank"  className="btn btn--icon">
            <FaGithub />
            </Link>
      
          <Link  to="https://instagram.com/orwysoft" target="_blank"  className="btn btn--icon">
            <FaInstagram />
            </Link>
            <Link  to="https://wa.me/908503030509?text=Merhabalar, hizmetleriniz hakkında bilgi almak istiyorum." target="_blank"  className="btn btn--icon">
            <FaWhatsapp />
            </Link>
            <Link  to="mailto:orkun@orwys.com" target="_blank"  className="btn btn--icon">
            <AiOutlineMail />
            </Link>
            <Link  to="tel:08503030509" target="_blank"  className="btn btn--icon">
            <BsTelephoneForward />
            </Link>
        </div>
        <div className="profile-links">
        <Link  to="tel:08503030509"> <small className="profile-user-handle"><BsTelephoneForward/>0850 303 05 09</small> </Link>
        <Link  to="mailto:orkun@orwys.com"> <small className="profile-user-handle"><AiOutlineMail/>contact@orwys.com</small>  </Link>
          <button className="btn btn--primary">{i18n.t('about:takipEt')}</button>
        </div>
      </article>
    </div>
  );
};

export default ProfilCard;
