import React, { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import './Orientation.css';
import { useTranslation } from 'react-i18next';





const Orientation = () => {
  const [selectedDot, setSelectedDot] = useState('1');
  const { t, i18n } = useTranslation(); 

  const handleDotClick = (event) => {
    setSelectedDot(event.target.getAttribute('data-description'));
  };

  const timelineData = [
    {
      id: "1",
      day: i18n.t('timeline:day_1'),
      label: i18n.t('timeline:label_1'),
      descriptions: [
        i18n.t('timeline:desc_1'),
        i18n.t('timeline:desc_2'),,
        i18n.t('timeline:desc_3'),,
        i18n.t('timeline:desc_4'),,
        i18n.t('timeline:desc_5'),
       
      ],
    },
    {
      id: "2",
      day: i18n.t('timeline:day_2'),
      label:i18n.t('timeline:label_2'),
      descriptions: [
        i18n.t('timeline:desc_6'),
        i18n.t('timeline:desc_7'),
        i18n.t('timeline:desc_8'),
      ],
    },
    {
      id: "3",
      day: i18n.t('timeline:day_3'),
      label:i18n.t('timeline:label_3'),
      descriptions: [
        i18n.t('timeline:desc_9'),
        i18n.t('timeline:desc_10'),
        i18n.t('timeline:desc_11'),
        i18n.t('timeline:desc_12'),
        i18n.t('timeline:desc_13'),
      ],
    },
    {
      id: "4",
      day: i18n.t('timeline:day_4'),
      label:i18n.t('timeline:label_4'),
      descriptions: [
        i18n.t('timeline:desc_14'),
        i18n.t('timeline:desc_15'),
        i18n.t('timeline:desc_16'),
        i18n.t('timeline:desc_17'),
      ],
    },
    {
      id: "5",
      day: i18n.t('timeline:day_5'),
      label:i18n.t('timeline:label_5'),
      descriptions: [
        i18n.t('timeline:desc_18'),
        i18n.t('timeline:desc_19'),
      ],
    },
    {
      id: "6",
      day: i18n.t('timeline:day_6'),
      label:i18n.t('timeline:label_6'),
      descriptions: [
        i18n.t('timeline:desc_20'),
        i18n.t('timeline:desc_21'),
      ],
    },
    {
      id: "7",
      day: i18n.t('timeline:day_7'),
      label:i18n.t('timeline:label_7'),
      descriptions: [
        i18n.t('timeline:desc_22'),
        i18n.t('timeline:desc_23'),
      ],
    },
  ];
  

  return (
    <div>
    <div id="orientation">
      <div className="container">
        <h1>
        {i18n.t('general:orientationTitle')}{' '}
          <span className="title-icon">
            <BsArrowRight />
          </span>
        </h1>
        <div className="flex-parent">
          <div className="input-flex-container">
          {timelineData.map((item) => (
  <React.Fragment key={item.id}>
    <input
      type="radio"
      name="timeline-dot"
      data-description={item.id}
      checked={selectedDot === item.id}
      onClick={handleDotClick}
    />
    <div className="dot-info" data-description={item.id}>
      <span className="year">{item.day}</span>
      <span className="label">{item.label}</span>
    </div>
  </React.Fragment>
))}

<div id="timeline-descriptions-wrapper">
  {timelineData.map((item) => (
    <p key={item.id} data-description={item.id}>
      {item.descriptions.map((desc, index) => (
        <React.Fragment key={index}>
          <BsArrowRight />
          {desc}
          <br />
        </React.Fragment>
      ))}
    </p>
  ))}
</div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  );
  
};

export default Orientation;
