import React, { useState, useEffect } from 'react';
import ThemeContext from '../../context/ThemeContext';
import { useTranslation } from 'react-i18next';
import { MdLightMode } from 'react-icons/md';
import { IoMoonOutline } from 'react-icons/io5';
import { TfiClose } from 'react-icons/tfi';
import { RiMenuFill } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import PageRoutes from '../../routes/PageRoutes';

const Header = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const clickHandle = (lang) => {
    i18n.changeLanguage(lang);
  };

  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };


const handleMenuClose = () => {
  setMenuOpen(false);
};


  // LANGUAGE AYARLARI
  const mapPath = (path, lang) => {
    const page = PageRoutes.find((route) => route.translations[lang] === path);

    if (page) {
      const targetLang = lang === 'en' ? 'tr' : 'en';
      const newPath = page.translations[targetLang];
      return newPath;
    } else {
      return path;
    }
  };

const dilfunction = (lang) => {
  const currentPath = location.pathname;

  // Eğer hizmet detay sayfasındaysa
  if (currentPath.includes('/hizmet/') || currentPath.includes('/services/')) {
    const newPath = lang === 'en' ? '/en/services' : '/hizmetler';
    return newPath;
  } else if (currentPath.includes('/project/') || currentPath.includes('/proje/')) {
    // Eğer proje detay sayfasındaysa
    const newPath = lang === 'en' ? '/en/projects' : '/projeler';
    return newPath;
  } else {
    // Diğer sayfalarda isek
    const newPath = mapPath(currentPath, lang === 'en' ? 'tr' : 'en');
    return lang === 'en' ? (newPath.startsWith('/en') ? newPath : `/en${newPath}`) : (newPath.startsWith('/en') ? newPath.replace('/en', '') : newPath);
  }
};

  const linkWithLanguage = (path) => {
    const newPath = mapPath(path, i18n.language === 'en' ? 'tr' : 'en');

    if (i18n.language === 'en') {
      return newPath.startsWith('/en') ? newPath : `/en${newPath}`;
    } else {
      return newPath.startsWith('/en') ? newPath.replace('/en', '') : newPath;
    }
  };


  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const languageSegment = pathSegments[0];

    // Türkçe için dil kodu yok, bu yüzden varsayılan olarak 'tr' kullanılıyor.
    // Eğer ilk segment 'en' ise, İngilizce olarak ayarla.
    if (languageSegment === 'en') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('tr');
    }
  }, [location]);




  return (
    <ThemeContext.Consumer>
      {({ isDarkMode, toggleTheme }) => (
        <header>
          <nav>
            <div className="topbar">
              <div className="Mobile-left">
                <div className="mobile-logo">
          <img src="../images/logo-sm.png" width={35} alt="Profile" />
        ORWYS</div>
              </div>
              <div className="mobile-right">
                <div className="language-dropdown">
                  <button onClick={toggleLanguageDropdown}>
                    {i18n.language.toUpperCase()}
                  </button>
                  <div
                    className={`language-dropdown-content ${
                      isLanguageDropdownOpen ? 'open' : ''
                    }`}
                  >
                    <Link
                      className="language-dropdown-link"
                      to={dilfunction('tr')}
                      onClick={() => clickHandle('tr')}
                    >
                      Türkçe
                    </Link>
                    <Link
                      className="language-dropdown-link"
                      to={dilfunction('en')}
                      onClick={() => clickHandle('en')}
                    >
                      English
                    </Link>
                  </div>
                </div>
                <div>
                  <button className="button-none" onClick={toggleTheme}>
                    {isDarkMode ? (
                      <MdLightMode size={23} />
                    ) : (
                      <IoMoonOutline size={23} />
                    )}
                  </button>
                </div>

                <div className="hamburger-menu" onClick={toggleMenu}>
                  {menuOpen ? <TfiClose size={23} /> : <RiMenuFill size={25} />}
                </div>
              </div>
            </div>
            <div className="menu-container">
              <div className="logo"><img src="../images/logo-sm.png" width={55} alt="Profile" /> ORWYS </div>
              <ul className={`menu ${menuOpen ? 'mobil-menu-open' : ''}`}>
                <li className="active">
                  <Link to={linkWithLanguage('/')} onClick={handleMenuClose}>
                    {t('navigation:Anasayfa')}
                  </Link>
                </li>
                <li>
                  <Link to={linkWithLanguage('/hakkimizda')} onClick={handleMenuClose}>
                    {t('Hakkımızda')}
                  </Link>
                </li>
                <li>
                  <Link to={linkWithLanguage('/projeler')} onClick={handleMenuClose}>
                    {t('Projeler')}
                  </Link>
                </li>
                <li>
                  <Link to={linkWithLanguage('/hizmetler')} onClick={handleMenuClose}>
                    {t('Hizmetler')}
                  </Link>
                </li>
                <li>
                  <Link to={linkWithLanguage('/iletisim')}onClick={handleMenuClose}>
                    {t('İletişim')}
                  </Link>
                </li>
                <li>
                  <div className="button-contact">
                    <Link to="tel:08503030509" className="get-a-demo-button">
                    {i18n.t('general:getInTouch')}
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </header>
      )}
    </ThemeContext.Consumer>
  );
};

export default Header;
