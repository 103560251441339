import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ThemeProvider from './context/ThemeProvider';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import NotFound from './pages/notfound/NotFound';
import './App.css';

import PageRoutes from './routes/PageRoutes';



function App() {




  const Layout = ({ children }) => {
    return (
      <div className="app">
        <ThemeProvider>
          <Header />
          {children}
          <Footer />
        </ThemeProvider>
      </div>
    );
  };

  return (
<div>


<Router>
      <Layout>
        <Routes>
          {PageRoutes.map((route) => (
            <React.Fragment key={route.path}>
              <Route
                path={route.translations['tr']}
                element={<route.component />}
              />
              <Route
                path={route.translations['en']}
                element={<route.component />}
              />
            </React.Fragment>
          ))}
          <Route path="/admin/*" />
          <Route path="*" element={<NotFound />} /> {/* Eşleşmeyen tüm yollar için */}
        </Routes>
      </Layout>
    </Router>
    </div>
  );
}

export default App;
