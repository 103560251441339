
import React, { useEffect, useState } from 'react';
import './About.css';
import Faq from '../../components/faq/Faq';
import ProfilCard from '../../components/profilcard/ProfilCard';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import axios from 'axios';


const About = () => {
  const {  i18n, t } = useTranslation();
  const [metaData, setMetaData] = useState({ title: '', description: '' });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/pages/list/${i18n.language}`)
      .then(response => {
        if (response.data && response.data.length) {
          // Dil koduna ve sayfa ismine göre doğru meta verileri bul
          const pageMeta = response.data.find(page => page.page_name === (i18n.language === 'en' ? 'About Us' : 'Hakkımızda'));
          if (pageMeta) {
            setMetaData({
              title: pageMeta.meta_title,
              description: pageMeta.meta_description
            });
          }
        }
      })
      .catch(error => console.error('Meta veri çekilirken hata oluştu:', error));
  }, [i18n.language]);





  return (


    <div className="about">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
      </Helmet>
      <div className="container-fluid">
        <h1>  {i18n.t('about:title')}</h1>
        <div className="container">
          <div className="col-left">
            <ProfilCard />
          </div>
          <div className="col-right">
            <div className="text-hakkimizda">
              <h2>{i18n.t('about:titleTwo')}</h2>
              <p>
              {i18n.t('about:descOneHakkimizda')}
                <br /> <br />
                {i18n.t('about:descTwoHakkimizda')}
              </p>
            </div>
          </div>
        </div>

        <Faq />

        <div className="container">
          <div className="col-6 green-gradient ">
            <div className="box-about">
              <h3>{i18n.t('about:vission')}</h3>
              <p>
              {i18n.t('about:vissionDesc')}
              </p>
              <button>{i18n.t('about:vissionTag')}</button>
            </div>
          </div>
          <div className="col-6 green-gradient">
            <div className="box-about">
              <h3>{i18n.t('about:mission')}</h3>
              <p>
              {i18n.t('about:missionDesc')}
              </p>
              <button>{i18n.t('about:missionTag')}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

