import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { TfiApple } from 'react-icons/tfi';
import { IoLaptopOutline } from 'react-icons/io5';
import { CiMobile3 } from 'react-icons/ci';
import { BsCodeSlash } from 'react-icons/bs';
import { IoPulseOutline } from 'react-icons/io5';
import './Projects.css';

const Projects = ({ projectsPerTab }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [currentTab, setCurrentTab] = useState(1);
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const getLanguagePrefix = () => {
    return i18n.language === 'en' ? '/en/project' : '/proje';
  };

  useEffect(() => {
    const fetchCategoriesAndProjects = async () => {
      try {
        const language = i18n.language;
        const categoriesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories/list/${language}`);
        const projectsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/list/${language}`);
        
        setCategories(categoriesResponse.data);
        setProjects(projectsResponse.data);
        
        // Eğer kategoriler varsa, ilk kategoriyi seçili yap
        if (categoriesResponse.data.length > 0) {
          setCurrentTab(categoriesResponse.data[0].category_id);
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
  
    fetchCategoriesAndProjects();
  }, [i18n.language]);

  const handleTabClick = (id) => {
    setCurrentTab(id);
  };

  const ICONS = {
    'BsCodeSlash': BsCodeSlash,
    'IoLaptopOutline': IoLaptopOutline,
    'TfiApple': TfiApple,
    'CiMobile3': CiMobile3,
    'IoPulseOutline': IoPulseOutline
  };

  const renderTabIcon = (iconName) => {
    const IconComponent = ICONS[iconName];
    if (IconComponent) {
      return <IconComponent />;
    }
    return null;
  };

  return (
    <div className="container-fluid">
      <div className="tabs">
        {categories.map((category) => (
          <button
            key={category.category_id}
            disabled={currentTab === category.category_id}
            onClick={() => handleTabClick(category.category_id)}
          >
            {renderTabIcon(category.icon)} <br />
            {category.name}
          </button>
        ))}
      </div>

      <div className="hero-section">
        <div className="card-grid">
          {projects
            .filter((project) => project.category_id === currentTab)
            .slice(0, projectsPerTab || projects.length)
            .map((project) => {
              const coverImage = project.images.find(image => image.image_type === 'kapak');
              const coverImageUrl = coverImage ? `${process.env.REACT_APP_API_URL}/${coverImage.image_url}` : 'defaultImageURL';
              const categoryName = categories.find(cat => cat.category_id === project.category_id)?.name || 'Kategori Bulunamadı';
              return (
                <Link className="card" to={`${getLanguagePrefix()}/${project.url}`} key={project.project_id}>
                  <div className="card__background" style={{ backgroundImage: `url(${coverImageUrl})` }}></div>
                  <div className="card__content">
                    <p className="card__category">{categoryName}</p>
                    <h3 className="card__heading">{project.title}</h3>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Projects;
