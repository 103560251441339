import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  Scrollbar
} from 'swiper/core';
import 'swiper/swiper-bundle.css';
import './Services.css';

const Services = () => {
  const { t, i18n } = useTranslation();
  const [services, setServices] = useState([]);
  const getLanguagePrefix = () => {
    return i18n.language === 'en' ? '/en/service' : '/hizmet';
  };
  SwiperCore.use([Autoplay, Pagination, Navigation, Scrollbar]);

  useEffect(() => {
    // API'den hizmetlerin çekilmesi
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/list/${i18n.language}`);
        setServices(response.data); // State'i güncelle
      } catch (error) {
        console.error('Hata:', error);
      }
    };

    fetchServices();
  }, [i18n.language]);

  return (
    <div className="services-container-fluid">
      <div className="services-container">
        <div className="text-area">
          <h1> {i18n.t('general:hizmetTitle')}</h1>
          <p>
           {i18n.t('general:hizmetDesc')}
          </p>
        </div>
        <Swiper
          spaceBetween={20}
          slidesPerView={5}
          loop={false}
          speed={1000}
          navigation
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 3,
            },
            // when window width is >= 992px
            992: {
              slidesPerView: 3,
            },
            1179: {
              slidesPerView: 4,
            },
            // when window width is >= 1200px
            1400: {
              slidesPerView: 5,
            },
          }}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {services.map((service) => {
  const coverImage = service.images.find(image => image.image_type === 'kapak');

  return (
    <SwiperSlide key={service.service_id}>
      <Link to={`${getLanguagePrefix()}/${service.url}`} style={{ textDecoration: 'none' }}>
        <div className="slide-style">
          {/* Eğer kapak resmi varsa göster, yoksa varsayılan bir resim kullan */}
          <img src={`${process.env.REACT_APP_API_URL}/${coverImage.image_url}`} alt={service.title} />
          <span>
            <h2>{service.title}</h2>
            <h6>{t('general:servicesButtonText')}</h6>
          </span>
        </div>
        </Link>
    </SwiperSlide>
  );
})}
        </Swiper>
      </div>
    </div>
  );
};

export default Services;
