import React from 'react'
import './NotFound.css';

const NotFound = () => {
  return (
    <div id="not-found">
      <div className='container'>
       <img src="../images/404.png" width={552} alt="Profile" />
      </div>
    </div>
  )
}

export default NotFound