import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'tr',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: ['navigation', 'homepage', 'about', 'general', 'timeline','card','faq','contact',"footer"],
    detection: {
      // Yalnızca URL'den dil algılamak için 'path' kullan
      order: ['path'], // 'querystring', 'cookie', vb. diğer yöntemleri kaldırın
      lookupFromPathIndex: 0, // URL'nin ilk bölümü dil kodunu içermeli
      nonExplicitWhitelist: true,
    },
    // diğer yapılandırma seçenekleri...
  });

export default i18n;
