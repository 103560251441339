import React, { useEffect, useState } from 'react';
import './ProjectDetail.css';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const ProjectDetail = () => {
  const {  i18n } = useTranslation();
  const { url  } = useParams();
  const [project, setProject] = useState(null);


  useEffect(() => {
    NativeFancybox.bind("[data-fancybox]", {
      infinite: true
    });
  }, []);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const language = i18n.language;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/single/${url}/${language}`);
        console.log(response.data);
        setProject(response.data);
      } catch (error) {
        console.error('Error fetching project: ', error);
      }
    };

    fetchProject();
  }, [url]); 

  const htmlContent = project?.description.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
  const projeRoute = i18n.language === 'tr' ? '/projeler' : '/en/projects';


  return (
    
    <div id="project-detail">
      <Helmet>
        <title>{project?.meta_title}</title>
        <meta name="description" content={project?.meta_description} />
      </Helmet>
      <div className="container m-100">
        <h1>
          <Link to={projeRoute} className="comeback">
            <AiOutlineArrowLeft />
          </Link>
          <div className="project-date">{project?.short_description} {project?.date}</div>
        </h1>
      </div>
      <div className="container ">
        <div className="col-4 pr-2 stabil ">
          <div className="project-information">
          <div className="project-title">{project?.title}</div>
            <p>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </p>
            <div className="project-text-features   ">
              <span>Kategori: </span>
              {project?.category}
            </div>
            <div className="project-text-features">
  <span>Teknoloji:</span> {project?.technology}
</div>
            <div className="project-text-features">
              <span>Tarih:</span> {project?.date}
            </div>
            <div className="project-text-features">
              <span>Website:</span> {project?.website}
            </div>
<Link
  className="project-view"
  to={project?.button_link} // Yönlendirme yapılacak URL
  target="_blank"
  rel="noopener noreferrer"
>
  {project?.button_text}
  <AiOutlineArrowRight />
</Link>
          </div>
        </div>

 <div className="col-8">
  {project && project.images
    .filter((image) => image.image_type === 'galeri')
    .map((image) => (
      <a
        href={`${process.env.REACT_APP_API_URL}/${image.image_url}`}
        data-fancybox="gallery"
        data-caption={project.title}
        key={image.image_id}
      >
        <img src={`${process.env.REACT_APP_API_URL}/${image.image_url}`} alt={project.title} />
      </a>
  ))}
</div>


        
      </div>
    </div>
  );
};

export default ProjectDetail;
